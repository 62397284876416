<template>
  <div>
    <v-container>
      <all-user />
    </v-container>
  </div>
</template>

<script>
import allUser from "../../components/User/allUser.vue";
export default {
  data() {
    return {};
  },
  components: {
    allUser,
  },
};
</script>

<style></style>
