<template>
  <div>
    <v-container>
      <all-business></all-business>
    </v-container>
  </div>
</template>

<script>
import allBusiness from "@/components/Business/allBusiness.vue";
export default {
  data() {
    return {};
  },
  components: {
    allBusiness,
  },
};
</script>

<style></style>
