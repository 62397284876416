<template>
  <div>
    <v-row class="mt-2">
      <v-col cols="6">
        <span class="headline font-weight-medium">User</span>
      </v-col>
      <v-col cols="6" class="d-flex justify-end">
        <v-btn class="primary ml-2 mt-1 text-capitalize" @click="addUserDialog = true"
          >Add</v-btn
        >
      </v-col>
    </v-row>
    <v-card class="mt-5">
      <v-card-title>
        <v-spacer></v-spacer>
        <!-- <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          filled
          rounded
          dense
          hide-details
        ></v-text-field> -->
      </v-card-title>
      <v-data-table :headers="headers" :items="alluser" :search="search">
        <template v-slot:item.details="{ item }">
          <v-btn icon @click="showDetails(item)">
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="details" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-5">
          User Info <v-spacer></v-spacer>
          <v-btn icon @click="details = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>

        <v-card-text>
          <v-list-item class="pl-0">
            <v-list-item-avatar size="80" color="grey">
              <img
                src="https://avatars0.githubusercontent.com/u/9064066?v=4&s=460"
                alt=""
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h5 mb-1 pl-3 font-weight-regular">
                {{userDetails.full_name}}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>User Name:</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              {{userDetails.user_name}}
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>Contact:</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              {{userDetails.contact_no}}
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>Email:</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              {{userDetails.email}}
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addUserDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-5">
          Add User
          <v-spacer></v-spacer>
          <v-btn icon @click="addUserDialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>

        <v-card-text>
          <v-text-field
            class="mt-5"
            dense
            outlined
            label="Name"
            v-model="name"
          ></v-text-field>
          <v-text-field
            outlined
            dense
            label="Username"
            v-model="userName"
          ></v-text-field>
          <v-text-field
            outlined
            dense
            label="Email"
            type="email"
            v-model="email"
          ></v-text-field>
          <v-text-field
            outlined
            dense
            type="number"
            label="Contact No"
            v-model="contact"
          ></v-text-field>
          <v-text-field
            @click:append="show = !show"
            outlined
            label="Password"
            dense
            v-model="password"
            :type="show ? 'text' : 'password'"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            hide-details
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="text-capitalize"
            @click="addUser()"
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters,mapMutations } from "vuex";
export default {
  data() {
    return {
      show: false,
      details: false,
      search: "",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      addUserDialog: false,
      toDate: false,
      name:"",
      userName:"",
      email:"",
      contact:"",
      password:"",
      userDetails:"",
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "full_name",
        },
        { text: "User Name", value: "user_name" },
        { text: "Email", value: "email" },
        {text:"Contact No", value: "contact_no"},
        { text: "Details", value: "details" },
      ],
      alluser:[],
      desserts: [
        {
          name: "Email",
          userName: "Fanincial",
          email: "business@gmail.com",
          details: "street#12,unknown,unknown",
        },
      ],
    }
  },
  created(){
    this.getAllUser();
  },
  methods:{
    ...mapActions("user",["reegisterUser","allUser"]),
    addUser(){
      this.addUserDialog= false
      let obj= {
        userName:this.userName,
        fullName:this.name,
        email:this.email,
        password:this.password,
        contactNo:this.contact
      }
      this.reegisterUser(obj).then((res)=>{
        console.log("user Added res ",res)
        this.getAllUser
      }).catch((err)=>{
        console.log("add user error ",err)
      })
    },
    getAllUser(){
      this.allUser().then((res)=>{
        console.log("response of all user data ",res)
        this.alluser=res.data.data
        this
      }).catch((err)=>{
        console.log("alll user error ",err)
      })
    },
    showDetails(item){
      this.details=true
      this.userDetails=item
      console.log("bdfvdfjdfbgdjfbhdjkgd",this.userDetails)

    }
  }
};
</script>

<style></style>
