<template>
  <div class="d-flex justify-center align-center" style="height:90vh">
    <v-card width="400" class="px-3">
      <v-card-title>Login</v-card-title>
      <v-card-text>
        <v-text-field outlined label="Email" v-model="email"></v-text-field>
        <v-text-field
          @click:append="show = !show"
          outlined
          label="Password"
          v-model="password"
          :type="show ? 'text' : 'password'"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          hide-details
        ></v-text-field>
        <div class="d-flex justify-end">
          <v-checkbox
            v-model="checkbox"
            dense
            label="Remember me?"
          ></v-checkbox>
          <!-- <a href="#" class="text-decoration-none"> Forgot Password?</a> -->
        </div>
        <v-btn
          class="white--text text-capitalize px-7 ml-auto mb-5"
          block
          color="blue"
          @click="userLogin"
        >
          Login
        </v-btn>
        <div class="text-center">
            <a href="#" > Forgot Password?</a> 
            
        </div>
      </v-card-text>

      <v-card-actions class="justify-center mb-5">
        <span>Privacy Policy</span> <v-divider vertical class="mx-5"></v-divider>
            <span>Terms & Condition</span>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters,mapMutations } from "vuex";
export default {
  data() {
    return {
      show: false,
      email:"",
      password:"",
      checkbox:false
    }
  },
  methods:{
      ...mapActions("auth",["login"]),
      userLogin(){
          let obj ={
              email:this.email,
              password:this.password
          }
          this.login(obj).then((res)=>{
              console.log("login ressponse data :",res)
              this.$store.commit("auth/setToken",res.data.data.token)
              if(res.statusText=="OK"){
                  this.$router.push({
              name: "Dashboard",
            });
              }

          }).catch((err)=>{
              console.log("login error",err)
          })
      }
  }
};
</script>

<style></style>
