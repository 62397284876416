<template>
  <div>
      <h1>Dashboard View</h1>
      <h1>Business Count : {{allbusinessCount}}</h1>
  </div>
</template>

<script>
import { mapActions, mapGetters,mapMutations } from "vuex";
export default {
  data(){
    return{
      allbusinessCount:""
    }
  },
  created(){
    this.getAllBusinessCount();
  },
  methods:{
    ...mapActions("business",["allBusinessCount"]),
    getAllBusinessCount(){
      this.allBusinessCount().then((res)=>{
        console.log("allBusiness Count resspone ",res)
        this.allbusinessCount=res.data.data
      }).catch((err)=>{
        console.log("allBusiness Count error ",err)
      })
    },
  }
}
</script>

<style>

</style>