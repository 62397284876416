<template>
  <div>
    <v-row class="mt-2">
      <v-col cols="6">
        <span class="headline font-weight-medium">Business</span>
      </v-col>
      <v-col cols="6" class="d-flex">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="From Date"
              append-icon="mdi-calendar"
              filled
              title
              dense
              single-line
              hide-details
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" @input="menu = false"></v-date-picker>
        </v-menu>

        <v-menu
          v-model="toDate"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="To Date"
              filled
              title
              dense
              hide-details
              single-line
              append-icon="mdi-calendar"
              class="ml-5"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" @input="toDate = false"></v-date-picker>
        </v-menu>
        <v-btn class="primary ml-2 mt-1 text-capitalize">Search</v-btn>
      </v-col>
    </v-row>
    <v-card class="mt-5">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          filled
          rounded
          dense
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="allbusiness" :search="search">
        <template v-slot:item.iron="{ item }">
          <v-btn icon @click="showDetail(item)">
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="details" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-5">
          Business Details <v-spacer></v-spacer>
          <v-btn icon @click="details = false,businessDdetail=true"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-switch
      v-model="businessDdetail"
      :label="businessDdetail ? 'Show User Info' : 'Show Business Info'"
    ></v-switch>
        </v-card-text>
        <v-card-text v-if="businessDdetail==true">
          <v-list-item class="pl-0">
            <v-list-item-avatar size="80" color="grey">
                <img src="https://avatars0.githubusercontent.com/u/9064066?v=4&s=460" alt="">
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h5 mb-1 pl-3 font-weight-regular">
                {{this.detailData.businessName}}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle >Business Type:</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
            {{this.detailData.businessType}}
        </v-list-item-action>
      </v-list-item>
      <v-divider ></v-divider>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle >Contact:</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
            {{this.detailData.businessContact}}
        </v-list-item-action>
      </v-list-item>
      <v-divider ></v-divider>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle >Email:</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
            {{this.detailData.businessEmail}}
        </v-list-item-action>
      </v-list-item>
      <v-divider ></v-divider>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle >Address:</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
            {{this.detailData.businessAddress}}
        </v-list-item-action>
      </v-list-item>
      <v-divider ></v-divider>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle >Total Members:</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
            {{this.detailData.totalMembers}}
        </v-list-item-action>
      </v-list-item>
      <v-divider ></v-divider>
        </v-card-text>
        <v-card-text v-else>
          <v-list-item class="pl-0">
            <v-list-item-avatar size="80" color="grey">
                <img src="https://avatars0.githubusercontent.com/u/9064066?v=4&s=460" alt="">
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h5 mb-1 pl-3 font-weight-regular">
                {{this.detailData.UserName}}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>  

          <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle >Email:</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
            {{this.detailData.UserEmail}}
        </v-list-item-action>
      </v-list-item>
      <v-divider ></v-divider>
          <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle >Country Name:</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
            {{this.detailData.UserLocation.country_name}}
        </v-list-item-action>
      </v-list-item>
      <v-divider ></v-divider>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle >Currency Code:</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
            {{this.detailData.UserLocation.currencyCode}}
        </v-list-item-action>
      </v-list-item>
      <v-divider ></v-divider>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle >Country Capital:</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
            {{this.detailData.UserLocation.country_capital}}
        </v-list-item-action>
      </v-list-item>
      <v-divider ></v-divider>
      
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle >Current Time:</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
            {{this.detailData.UserLocation.current_time}}
        </v-list-item-action>
      </v-list-item>
      <v-divider ></v-divider>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle >Time Zone Name:</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
            {{this.detailData.UserLocation.time_zone_name}}
        </v-list-item-action>
      </v-list-item>
      <v-divider ></v-divider>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle >Latitude:</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
            {{this.detailData.UserLocation.latitude}}
        </v-list-item-action>
      </v-list-item>
      <v-divider ></v-divider>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle >Longitude:</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
            {{this.detailData.UserLocation.longitude}}
        </v-list-item-action>
      </v-list-item>
      <v-divider ></v-divider>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters,mapMutations } from "vuex";
export default {
  data() {
    return {
      details: false,
      search: "",
      businessDdetail:true,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      toDate: false,
      detailData:"",
      headers: [
        {
          text: "Business Name",
          align: "start",
          sortable: false,
          value: "businessName",
        },
        { text: "Business Type", value: "businessType" },
        { text: "Contact", value: "businessContact" },
        { text: "Email", value: "businessEmail" },
        { text: "Address", value: "businessAddress" },
        { text: "Details", value: "iron" },
      ],
      allbusiness:[],
      desserts: [
        {
          name: "New business",
          calories: "Fanincial",
          fat: "03568899789",
          carbs: "business@gmail.com",
          protein: "street#12,unknown,unknown",
          iron: "1%",
        },
      ],
    }
  },
  created(){
    this.getAllBusiness();
  },
  methods:{
    ...mapActions("business",["allBusiness"]),
    getAllBusiness(){
      this.allBusiness().then((res)=>{
        console.log("allBusiness resspone ",res)
        this.allbusiness=res.data.data
      }).catch((err)=>{
        console.log("allBusiness error ",err)
      })
    },
    showDetail(item){
      this.details= true
       this.detailData= item
      console.log("ndcvndfbvdnv",this.detailData)
    }
  }
};
</script>

<style></style>
