<template>
  <div>
      <dashboard />
  </div>
</template>

<script>
import dashboard from '../../components/Dashboard/dashboard.vue'
export default {
  components: { dashboard },
    data(){
        return{

        }
    }
}
</script>

<style>

</style>